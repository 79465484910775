<template>
	<v-app class="fs_main images">
		
		<v-container>
			<v-row>
				<v-col>
					<div class="fs_main_header">
						<h1>{{ projectName }}</h1>
						<!-- <v-btn class="edit"></v-btn> -->
						<span class="split">
							<h2><span class="id">{{ placeId }}</span>{{ placeName }}</h2>
						</span>
					</div>
				</v-col>
			</v-row>
			
			<v-row>
				<v-col>
					<header v-if="!showHiddenPhoto" class="fs_block_header">{{ cameraName }} 過去画像一覧</header>
					<header v-else class="fs_block_header">{{ cameraName }} 非表示画像一覧</header>
					<div class="image-navi">
						<div class="time_display"></div>
						<v-btn v-if="!showHiddenPhoto && login.role == 'admin'" class="download" @click="switchShowPhotoMode">非表示画像一覧</v-btn>
						<v-btn v-if="showHiddenPhoto" class="download" @click="switchNormalMode">過去画像一覧</v-btn>
						<v-btn v-if="!showHiddenPhoto" class="download" @click="download">ダウンロード</v-btn>
						<datetime v-model="datetime" type="datetime" input-id="startDate" @close="dateUpdated" :minute-step="30" :auto="true"  >
							<label slot="before" for="startDate">開始日時変更</label>
						</datetime>
					</div>
					
					<div class="fs_photos">
						
						<template >
							<figure v-for="(n, key) in files" :key="key">
								<div class="fs_cam_image">
									<span v-if="n.photoType != 'V' || !config.enableVideo">
										<img v-auth-image="`https://${config.defaultOriginString}/photo?cid=${cid}&name=${n.fileName}&type=thumb`"	@click="showImage(key)"/>
									</span>
									<span v-else>
										<video width="375" height="270" controls :src="videos[n.fileName]" />
									</span>
									<!-- <img src="/img/photo_test.jpg" 	@click="showImage(key)" /> -->
								</div>
								<figcaption>
									<span v-if="displayLatest(key) != ''" class="latest">{{ displayLatest(key) }}</span>
									<span v-if="n.photoType == 'M'" class="motion">動検知</span>
									<span v-if="n.photoType == 'V'" class="motion">動画</span>
									<span v-if="n.photoType == 'S'" class="asap">即時</span>
									<span class="date">{{ convertDate(n.fileName) }}</span>
									<v-btn v-show="!showHiddenPhoto && login.role == 'admin' && key != 0" style="margin-left: 5px;" x-small @click="hidePhoto(n.fileName)">非表示</v-btn>
									<v-btn v-show="showHiddenPhoto && login.role == 'admin'" style="margin-left: 5px;" x-small @click="showPhoto(n.fileName)">再表示</v-btn>
								</figcaption>
								
							</figure>
						</template>
						
					</div>
				</v-col>
			</v-row>
		</v-container>
		<v-dialog v-model="dialogDelete" max-width="500px">
			<v-card>
				<v-card-title class="headline">
					本当にこの画像を非表示にしますか？
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeDelete"
						>キャンセル</v-btn
					>
					<v-btn color="blue darken-1" text @click="deleteConfirm">OK</v-btn>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-footer >
			©2021 <a href="https://www.vegetalia.co.jp/" >vegetalia</a>, inc.
		</v-footer>
		<v-dialog v-model="imageDialog" >
			<div class="clickarea" @click="hideImage">
				<v-btn @click="hideImage">閉じる</v-btn>
				<img v-auth-image="dialogImage" class="hd-image" />
			</div>
		</v-dialog>
			<v-dialog v-model="downloadDialog" max-width="500px">
				<v-card>
					<v-card-title>
						<span class="headline">ダウンロード</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<span v-if="downloadUrl"><a :href="downloadUrl">zipダウンロード</a> (有効期限: 3分)</span>
							<span ref="downloadMsg" v-else>ファイルを生成しています。しばらくお待ち下さい。この処理には最大30秒かかります。</span>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="close"
						>
							閉じる
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
	</v-app>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import VueAuthImage from "vue-auth-image";
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(VueAuthImage);

export default {
	computed: {
		// item: function () {
// 			return this.$store.getters.getPlaceById(this.$route.params.id);
// 		}
	},
	components: {
		datetime : Datetime
	},
	mounted() {
		this.showLoading();
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
		axios
			.get(
				"https://" +
					this.config.defaultOriginString +
					"/api/photo-list?cid=" +
					this.cid +
					"&n=100",
				{
					headers: {
						Authorization: "Bearer " + this.$store.state.auth.data.token,
					},
				}
			)
			.then((res) => {
				// 成功した場合
				if (res && res.data && res.data.status == "success") {
					for (var i = 0; i < res.data.list.length; i++) {
						if (res.data.list[i].photoType == "V") {
							this.setVideoUrl(this.cid, res.data.list[i].fileName);
						} else {
							if (this.config.bigImages) {
								this.setBigimageUrl(this.cid, res.data.list[i].fileName);
							}
						}
					}
					this.files = res.data.list;
					this.fileLoadCount++;
					this.hideLoading();
					console.log("Success: " + JSON.stringify(res.data));
					this.updateTimeDisplay()
				} else {
					this.hideLoading();
					console.log("Fail: " + JSON.stringify(res));
				}
			})
			.catch((error) => {
				this.hideLoading();
				console.log(error);
			});
	},
	created() {},

	methods: {
		close: function() {
			this.downloadUrl = "";
			this.downloadDialog = false;
		},
		showPhoto: function(dateStr) {
			this.showLoading();
			dateStr = "&d=" + dateStr;
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/photo-list?requestType=false&cid=" +
						this.cid +
						dateStr,
					{
						headers: {
							Authorization: "Bearer " + this.$store.state.auth.data.token,
						},
					}
				)
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						this.switchShowPhotoMode();
					} else {
						console.log("Error: " + JSON.stringify(res.data));
						alert("エラーです。もう一度やり直してください。");
					}
				})
				.catch((error) => {
					this.hideLoading();
					this.$refs.downloadMsg.innerHTML = "エラーです。もう一度やり直してください。";
					console.log(error);
				});
		},
		hidePhoto: function(dateStr) {
			this.dialogDelete = true;
			this.hidePhotoDateStr = dateStr;
		},
		download: function() {
			this.downloadDialog = true;
			this.showLoading();
			let dateStr = "";
			this.zipPassword = Math.random().toString(32).substring(4);
			this.numSeconds = Math.max(this.files.length * 300, 10000);
			if (this.datetime) {
				dateStr = "&d=" + this.dateFormat(new Date(this.datetime), "yyyyMMddhhmmss");
			}
			console.log(dateStr);
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/photo-list?zipRequest=true&cid=" +
						this.cid +
						"&n=100" +
						dateStr,
					{
						headers: {
							Authorization: "Bearer " + this.$store.state.auth.data.token,
						},
					}
				)
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						let that = this;
						setTimeout(function(){
							console.log("URL Loaded");
							that.downloadUrl = res.data.url;
							that.hideLoading();
						}, this.numSeconds);
					} else {
						console.log("Success: " + JSON.stringify(res.data));
						this.$refs.downloadMsg.innerHTML = "エラーです。もう一度やり直してください。";
					}
				})
				.catch((error) => {
					this.hideLoading();
					this.$refs.downloadMsg.innerHTML = "エラーです。もう一度やり直してください。";
					console.log(error);
				});
		},
		setVideoUrl: function(cid, fileName) {
			axios.get("https://" + this.config.defaultOriginString + "/photo?cid=" + cid + "&name=" + fileName + "&type=video",
				{headers: {
					Authorization: "Bearer " + this.$store.state.auth.data.token,
				}})
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.status == "200") {
						// console.log("Video Success: " + JSON.stringify(res.data));
						this.$set(this.videos, fileName, res.data);
					} else {
						console.log("Video Fail: " + JSON.stringify(res));
					}
				}).catch((error) => {
					console.log("Video Error: " + error);
				}
			);
		},
		setBigimageUrl: function(cid, fileName) {
			axios.get("https://" + this.config.defaultOriginString + "/photo?cid=" + cid + "&name=" + fileName + "&type=bigimage",
				{headers: {
					Authorization: "Bearer " + this.$store.state.auth.data.token,
				}})
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.status == "200") {
						console.log("Big Image Success: " + JSON.stringify(res.data));
						this.$set(this.bigimages, fileName, res.data);
					} else {
						console.log("Big Image Fail: " + JSON.stringify(res));
					}
				}).catch((error) => {
					console.log("Big Image Error: " + error);
				}
			);
		},
		showImage: function (key) {
			this.imageDialog = true;
			//this.dialogImage = '/img/photo_test_hd.jpg'
			if (this.config.bigImages) {
				this.dialogImage = this.bigimages[this.files[key].fileName];
			} else {
				// this.dialogImage = '/img/photo_test_hd.jpg'
				this.dialogImage =
					"https://" +
					this.config.defaultOriginString +
					"/photo?cid=" +
					this.cid +
					"&name=" +
					this.files[key].fileName +
					"&type=raw";
			}
		},
		hideImage: function(){
			this.imageDialog = false;
			this.dialogImage = ''
		},
		convertDate: function (n) {
			return (
				n.substring(0, 4) +
				"-" +
				n.substring(4, 6) +
				"-" +
				n.substring(6, 8) +
				" " +
				n.substring(8, 10) +
				":" +
				n.substring(10, 12) // +
				// 				":" +
				// 				n.substring(12, 14)
			);
		},
		displayLatest: function (key) {
			if (key == 0) return "最新";
			else return "";
		},
		switchNormalMode: function() {
			this.$router.go({path: this.$router.currentRoute.path, force: true});
		},
		switchShowPhotoMode: function() {
			this.showLoading();
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/photo-list?showPhoto=true&cid=" +
						this.cid +
						"&n=100",
					{
						headers: {
							Authorization: "Bearer " + this.$store.state.auth.data.token,
						},
					}
				)
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						this.showHiddenPhoto = true;
						this.files = res.data.list;
						this.displayLatest = function (key) {
							return "";
						};
						this.hideLoading();
						console.log("Success: " + JSON.stringify(res.data));
					} else {
						this.hideLoading();
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
				});
		},
		dateUpdated: function () {
			if (this.datetime != this.last_datetime){
				this.showLoading();
				axios
					.get(
						"https://" +
							this.config.defaultOriginString +
							"/api/photo-list?cid=" +
							this.cid +
							"&n=100" +
							"&d=" +
							this.dateFormat(new Date(this.datetime), "yyyyMMddhhmmss"),
						{
							headers: {
								Authorization: "Bearer " + this.$store.state.auth.data.token,
							},
						}
					)
					.then((res) => {
						// 成功した場合
						if (res && res.data && res.data.status == "success") {
							this.files = res.data.list;
							this.displayLatest = function (key) {
								return "";
							};
							this.fileLoadCount++;
							this.hideLoading();
							console.log("Success: " + JSON.stringify(res.data));
							this.updateTimeDisplay(new Date(this.datetime))
							this.last_datetime = this.datetime
						} else {
							this.hideLoading();
							console.log("Fail: " + JSON.stringify(res));
						}
					})
					.catch((error) => {
						this.hideLoading();
						console.log(error);
					});
			}
		},
		dateFormat: function (date, format) {
			let _fmt = {
				hh: function (date) {
					return ("0" + date.getHours()).slice(-2);
				},
				h: function (date) {
					return date.getHours();
				},
				mm: function (date) {
					return ("0" + date.getMinutes()).slice(-2);
				},
				m: function (date) {
					return date.getMinutes();
				},
				ss: function (date) {
					return ("0" + date.getSeconds()).slice(-2);
				},
				dd: function (date) {
					return ("0" + date.getDate()).slice(-2);
				},
				d: function (date) {
					return date.getDate();
				},
				s: function (date) {
					return date.getSeconds();
				},
				yyyy: function (date) {
					return date.getFullYear() + "";
				},
				yy: function (date) {
					return date.getYear() + "";
				},
				t: function (date) {
					return date.getDate() <= 3
						? ["st", "nd", "rd"][date.getDate() - 1]
						: "th";
				},
				w: function (date) {
					return ["Sun", "$on", "Tue", "Wed", "Thu", "Fri", "Sat"][
						date.getDay()
					];
				},
				MMMM: function (date) {
					return [
						"January",
						"February",
						"$arch",
						"April",
						"$ay",
						"June",
						"July",
						"August",
						"September",
						"October",
						"November",
						"December",
					][date.getMonth()];
				},
				MMM: function (date) {
					return [
						"Jan",
						"Feb",
						"$ar",
						"Apr",
						"$ay",
						"Jun",
						"Jly",
						"Aug",
						"Spt",
						"Oct",
						"Nov",
						"Dec",
					][date.getMonth()];
				},
				MM: function (date) {
					return ("0" + (date.getMonth() + 1)).slice(-2);
				},
				M: function (date) {
					return date.getMonth() + 1;
				},
				$: function (date) {
					return "M";
				},
			};
			let _priority = [
				"hh",
				"h",
				"mm",
				"m",
				"ss",
				"dd",
				"d",
				"s",
				"yyyy",
				"yy",
				"t",
				"w",
				"MMMM",
				"MMM",
				"MM",
				"M",
				"$",
			];
			return _priority.reduce(
				(res, fmt) => res.replace(fmt, _fmt[fmt](date)),
				format
			);
		},
		updateTimeDisplay: function(t){
			if(t){
				document.querySelector('.time_display').innerHTML = this.dateFormat(t, "yyyy-MM-dd hh:mm") + ' から100件'
			} else {
				document.querySelector('.time_display').innerHTML = '最新の100件'
			}
			
		},
		getPlaceInfo: function(){
			let placeObject = {}
			for (let place of this.$store.state.places.data){
				for (let camera of place.cameras){
					if(camera.id == this.$route.params.id){
						placeObject.id =  place.id
						placeObject.name = place.name
						placeObject.camName = camera.name
					}
				}
			}
			return placeObject
		},
		showLoading : function(){
			this.$emit('loadShowHide',true);
		},
		hideLoading : function(){
			this.$emit('loadShowHide',false);
		},
		closeDelete() {
			this.dialogDelete = false;
		},
		deleteConfirm() {
			this.showLoading();
			this.hidePhotoDateStr = "&d=" + this.hidePhotoDateStr;
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/photo-list?requestType=true&cid=" +
						this.cid +
						this.hidePhotoDateStr,
					{
						headers: {
							Authorization: "Bearer " + this.$store.state.auth.data.token,
						},
					}
				)
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						this.$router.go({path: this.$router.currentRoute.path, force: true});
					} else {
						console.log("Error: " + JSON.stringify(res.data));
						alert("エラーです。もう一度やり直してください。");
					}
				})
				.catch((error) => {
					this.hideLoading();
					this.$refs.downloadMsg.innerHTML = "エラーです。もう一度やり直してください。";
					console.log(error);
				});
		}
	},
	data() {
		return {
			files: [],
			videos: {},
			bigimages: {},
			fileLoadCount: 0,
			numSeconds: 10000,
			datetime: "",
			imageDialog: false,
			downloadDialog: false,
			dialogDelete: false,
			downloadUrl: "",
			zipPassword: "",
			dialogImage: "",
			showHiddenPhoto: false,
			cid: this.$route.params.id,
			projectName : this.$store.state.project.data.name,
			placeId : ("000" + this.getPlaceInfo().id ).slice(-3),
			placeName : this.getPlaceInfo().name,
			cameraName  : this.getPlaceInfo().camName,
			last_datetime : 0,
			login: this.$store.state.user.data,
			hidePhotoDateStr: ""
		};
	},
};
</script>

